import React, { useEffect, useState } from 'react'

import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'
import { cn } from '../../lib/utils'
import { createThumbnail } from '../../workers/thumbnailWorker'

interface ImageCardProps {
  file?: File
  url?: string
  title?: string
  body?: React.ReactNode
  onClick?: () => void
  className?: string
  dimensions?: string
  fileSize?: string
}

const ImageCard: React.FC<ImageCardProps> = ({
  file,
  url,
  title,
  body,
  onClick,
  className,
  dimensions,
  fileSize,
}) => {
  const { ref, isInView } = useIntersectionObserver('1000px 0px', 100)
  const [thumbnail, setThumbnail] = useState<string | null>(null)
  const [imageError, setImageError] = useState(false)

  useEffect(() => {
    if (isInView) {
      if (file) {
        createThumbnail(file, 200, setThumbnail)
      } else if (url) {
        setThumbnail(url)
      }
    } else {
      setThumbnail(null)
    }
  }, [isInView, file, url])

  return (
    <div
      ref={ref}
      className={cn(
        'group relative overflow-hidden rounded-lg shadow-md',
        'transition-all duration-200 hover:shadow-lg',
        'focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2',
        onClick && 'cursor-pointer',
        className,
      )}
      onClick={onClick}
    >
      {/* Image Container with Fixed Aspect Ratio */}
      <div className="aspect-h-1 aspect-w-1 w-full h-full">
        {!isInView || (!thumbnail && !imageError) ? (
          <div className="flex h-full w-full items-center justify-center bg-gray-800">
            <img
              src="icon_white_square.png"
              alt="Loading"
              className="object-cover "
            />
          </div>
        ) : imageError ? (
          <div className="flex h-full w-full items-center justify-center bg-gray-800">
            <span className="text-sm text-gray-400">Image unavailable</span>
          </div>
        ) : (
          <div className="flex h-full w-full items-center justify-center bg-gray-800">
            <img
              src={thumbnail || ''}
              alt={title || 'Image'}
              className="h-full w-full object-cover transition-transform duration-200 group-hover:scale-105"
              onError={() => setImageError(true)}
            />
          </div>
        )}
      </div>

      {/* Content Overlay */}
      <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/90 via-black/60 to-transparent p-3">
        {title && (
          <h3 className="text-sm font-bold text-white sm:text-base">{title}</h3>
        )}
        <div className="mt-0.5 flex flex-col text-xs text-gray-300">
          {fileSize && <span>{fileSize}</span>}
          {dimensions && <span>{dimensions}</span>}
          {body}
        </div>
      </div>
    </div>
  )
}

export default ImageCard
