import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import React from 'react'

import { cn } from '../../lib/utils'
import Spinner from '../Spinner'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary',
  {
    variants: {
      variant: {
        default: 'bg-primary text-on-primary hover:bg-primary-light',
        creative: 'bg-creative text-on-primary hover:bg-creative-light',
        destructive:
          'bg-destructive text-on-primary hover:bg-destructive-light',
        secondary: 'bg-secondary text-on-secondary hover:bg-secondary-light',
        outline:
          'border border-accent text-on-surface hover:bg-accent hover:text-on-surface',
        ghost: 'hover:bg-surface shadow-none hover:bg-surface-1',
      },
      size: {
        sm: 'h-9 px-3 rounded-md',
        md: 'h-10 px-4 py-2',
        lg: 'h-11 px-8 rounded-md',
        icon: 'h-10 w-10',
        wide: 'h-10 w-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
  disabled?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading = false,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn([
          buttonVariants({ variant, size, className }),
          (isLoading || disabled) && 'pointer-events-none opacity-75',
        ])}
        disabled={isLoading || disabled}
        ref={ref}
        {...props}
      >
        {/* This allows the button to maintain it size whilst showing as loading */}
        <>
          <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
            {props.children}
          </div>
          {isLoading && (
            <Spinner
              size={size === 'wide' ? 'md' : size}
              className="absolute flex items-center justify-center"
            />
          )}
        </>
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
