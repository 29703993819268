import React from 'react'

import Logo from '../Logo'

interface AuthPageProps {
  title: string
  children: React.ReactNode
}
export default function AuthPage(props: AuthPageProps) {
  return (
    <div className="bg-background flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo className="mx-auto h-10 w-auto" />
        <h2 className="text-on-background mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
          {props.title}
        </h2>
      </div>
      {props.children}
    </div>
  )
}
