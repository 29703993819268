import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { Button } from '../../components/Button'
import DataTable from '../../components/DataTable'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { useFormData } from '../../hooks/useFormData'
import { fetchOrganisations } from '../../lib/clientHelpers'
import { columns } from './columns'

export default function Organisations() {
  const { userDetails } = useAuth()
  const queryClient = useQueryClient()
  const [createItemOpen, setCreateItemOpen] = useState(false)
  const [viewItemOpen, setViewItemOpen] = useState(false)
  const [selectedOrganisationId, setSelectedOrganisationId] =
    useState<string>('')

  const [createState, handleCreateChange, resetCreateForm] = useFormData({
    orgName: '',
  })

  const { organisationClient } = useClient()

  const { data: organisations = [], isLoading: isLoadingOrganisations } =
    useQuery({
      queryKey: ['organisations', userDetails.id],
      queryFn: () => fetchOrganisations(organisationClient, userDetails.id),
    })

  const { data: organisation } = useQuery({
    queryKey: ['organisation', selectedOrganisationId],
    queryFn: () =>
      organisationClient.getOrganisation({
        orgId: selectedOrganisationId,
      }),
    enabled: !!selectedOrganisationId,
  })

  const createMutation = useMutation({
    mutationFn: async (event: React.FormEvent) => {
      event.preventDefault()
      setCreateItemOpen(false)

      const newOrg = await organisationClient.createOrganisation({
        name: createState.orgName,
      })

      await organisationClient.createOrganisationUser({
        orgId: newOrg.id,
        userId: userDetails.id,
      })

      return newOrg
    },
    onSuccess: () => {
      resetCreateForm()
      queryClient.invalidateQueries({
        queryKey: ['organisations', userDetails.id],
      })
    },
  })

  const handleCreateItem = async () => {
    setCreateItemOpen(true)
  }

  const handleView = async (event: React.FormEvent) => {
    event.preventDefault()
    setViewItemOpen(false)
  }

  const handleViewItem = async (orgId: string) => {
    setViewItemOpen(true)
    setSelectedOrganisationId(orgId)
  }

  const deleteMutation = useMutation({
    mutationFn: async (orgId: string) => {
      await organisationClient.deleteOrganisation({ orgId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['organisations', userDetails.id],
      })
    },
  })

  const Inputs = [
    {
      id: 'orgName',
      label: 'Organisation Name',
      value: organisation?.name,
    },
    { id: 'orgId', label: 'Organisation Id', value: organisation?.id },
    {
      id: 'orgCreated',
      label: 'Organisation Created',
      value: organisation?.created?.toString(),
    },
    {
      id: 'orgUpdated',
      label: 'Organisation Updated',
      value: organisation?.updated?.toString(),
    },
  ]

  const renderInputs = () => {
    return Inputs.map((field) => (
      <div key={field.id}>
        <Input
          label={field.label}
          id={field.id}
          name={field.id}
          type="text"
          readOnly={true}
          value={field.value}
        />
      </div>
    ))
  }

  return (
    <div>
      <Modal
        title="Create Organisation"
        icon={<PlusCircleIcon />}
        isOpen={createItemOpen}
        onClose={() => {
          setCreateItemOpen(false)
          resetCreateForm()
        }}
      >
        <form onSubmit={createMutation.mutate} className="space-y-6">
          <Input
            label="Organisation Name"
            id="orgName"
            name="orgName"
            type="text"
            autoFocus={true}
            value={createState.orgName}
            onChange={handleCreateChange}
          />
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button type="submit">Submit</Button>
          </div>
        </form>
      </Modal>

      <Modal
        title="View Organisation"
        icon={<PencilSquareIcon />}
        isOpen={viewItemOpen}
        onClose={() => setViewItemOpen(false)}
      >
        <form onSubmit={handleView} className="flex-col space-y-6">
          {renderInputs()}

          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button type="submit">Close</Button>
          </div>
        </form>
      </Modal>

      <div>
        <DataTable
          data={organisations}
          columns={columns}
          title="Organisations"
          description="Organisations you are a member of."
          isLoading={isLoadingOrganisations}
          onCreate={handleCreateItem}
          tableOptions={{
            meta: {
              onDelete: deleteMutation.mutate,
              onView: handleViewItem,
            },
          }}
        />
      </div>
    </div>
  )
}
