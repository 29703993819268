import React from 'react'

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from '../../components/Card'

interface PageHeaderProps {
  title: string
  subtitle?: string
  children?: React.ReactNode
}
export default function PageHeader(props: PageHeaderProps) {
  return (
    <Card className="p-4">
      <CardTitle>{props.title}</CardTitle>
      <CardDescription>{props.subtitle}</CardDescription>
      {props.children && <CardContent>{props.children}</CardContent>}
    </Card>
  )
}
