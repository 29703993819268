import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import CardGrid from '../../components/CardGrid'
import Spinner from '../../components/Spinner'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { fetchAssetSets, fetchProjects } from '../../lib/clientHelpers'

export default function AssetSets() {
  const auth = useAuth()
  const { projectClient, assetClient } = useClient()

  const navigate = useNavigate()

  const handleCreate = () => {
    navigate('/asset-sets/create')
  }

  // TODO: This should be a separate query for each group id so they can be cached individually
  const { data: projects = [], isLoading: isLoadingProjects } = useQuery({
    queryKey: ['projects', auth.activeOrganisation.id, projectClient],
    queryFn: () => fetchProjects(auth.activeOrganisation.id, projectClient),
  })

  // TODO: This should be a separate query for each project id so they can be cached individually
  const { data: assetSets = [], isLoading: isLoadingAssetSets } = useQuery({
    queryKey: ['assetSets', projects],
    queryFn: () => fetchAssetSets(projects, assetClient),
    enabled: projects?.length > 0,
  })

  if (isLoadingProjects || isLoadingAssetSets) {
    return <Spinner size="page" className="text-primary" />
  }

  return (
    <div className="h-full">
      <CardGrid
        title="Asset Sets"
        description="Asset Sets you own."
        onCreate={handleCreate}
        data={assetSets?.map((assetSet) => {
          return {
            title: assetSet.name,
            id: assetSet.id,
            details: [`Type: ${assetSet.assetSetType}`],
            image: 'icon_white_square.png',
            link: `/asset-sets/${assetSet.id}`,
          }
        })}
      />
    </div>
  )
}
