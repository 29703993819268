import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import Map from '../../components/Map'
import Spinner from '../../components/Spinner'
import { useAuth } from '../../context/AuthContext'

export default function Maps() {
  const auth = useAuth()
  const { assetId } = useParams()

  const authQuery = useQuery({
    queryKey: ['authAccessToken', auth],
    queryFn: () => auth.getAccessToken(),
  })

  if (authQuery.isLoading) {
    return <Spinner size="page" className="text-primary" />
  }

  if (authQuery.isError) {
    return <div>Error {authQuery.error.message}</div>
  }

  if (!authQuery.data) {
    return <div>No access token available</div>
  }

  if (!assetId) {
    return <div>No asset ID provided</div>
  }

  return <Map assetId={assetId} />
}
