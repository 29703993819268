import { AssetServiceClient, AssetSet } from '@droidmap/asset-service-contract'
import {
  Group,
  Organisation,
  OrganisationServiceClient,
} from '@droidmap/organisation-service-contract'
import {
  Project,
  ProjectServiceClient,
  Task,
} from '@droidmap/project-service-contract'

// TODO: Handle pagination with user controls
export async function fetchOrganisations(
  organisationClient: OrganisationServiceClient,
  userId: string,
): Promise<Organisation[]> {
  let next: object | undefined = undefined
  const orgs: Organisation[] = []
  do {
    const response = await organisationClient.listOrganisationsByUser({
      userId: userId,
      next,
    })

    if (!response) {
      break
    }
    orgs.push(...(response.items || []))

    next = response.next
  } while (next)

  const filteredOrgs = orgs.filter(
    (org): org is Organisation => org !== undefined,
  )

  return filteredOrgs
}

// TODO: Handle pagination with user controls
export async function fetchGroups(
  organisationClient: OrganisationServiceClient,
  userId: string,
  orgId: string,
): Promise<Group[]> {
  let next: object | undefined = undefined
  const groups: Group[] = []

  do {
    const response = await organisationClient.listGroupsByOrganisationUser({
      userId,
      orgId,
      next,
    })

    groups.push(...(response.items || []))

    next = response.next
  } while (next)

  return groups
}

// TODO: Handle pagination with user controls
export async function fetchProjects(
  orgId: string,
  projectClient: ProjectServiceClient,
): Promise<Project[]> {
  let next: object | undefined = undefined
  const projects: Project[] = []

  do {
    const response = await projectClient.listProjectsByOrganisation({
      orgId,
      next,
    })

    if (!response) {
      break
    }

    projects.push(...response.items)

    next = response.next
  } while (next)

  return projects
}

export async function fetchAssetSets(
  projects: Project[],
  assetClient: AssetServiceClient,
): Promise<AssetSet[]> {
  const allAssetSets: AssetSet[] = []

  for (const project of projects) {
    let next: object | undefined = undefined
    let projectAssetSets: AssetSet[] = []

    do {
      const response = await assetClient.listAssetSetsByProject({
        projectId: project.id,
        next,
      })

      if (!response) {
        break
      }

      projectAssetSets = response.items || []
      allAssetSets.push(...projectAssetSets)

      next = response.next
    } while (next)
  }
  return allAssetSets
}

export async function fetchTasks(
  projects: Project[],
  projectClient: ProjectServiceClient,
): Promise<Task[]> {
  const allTasks: Task[] = []

  for (const project of projects) {
    let projectTasks: Task[] = []

    try {
      const response = await projectClient.listTasksByProject({
        projectId: project.id,
      })

      projectTasks = response.items || []
      allTasks.push(...projectTasks)
    } catch (error) {
      console.error(`Error fetching tasks for project ${project.id}:`, error)
    }
  }
  return allTasks
}
