import { EyeIcon, TrashIcon } from '@heroicons/react/20/solid'
import { ColumnDef } from '@tanstack/react-table'

import { Task } from '@droidmap/project-service-contract'

import { Badge } from '../../components/Badge'
import { Button } from '../../components/Button'

export const columns: ColumnDef<Task, keyof Task>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: (info) => info.getValue(),
  },
  // TODO: Map colour to type
  {
    accessorKey: 'type',
    header: 'Type',
    cell: (info) => <Badge color={'red'}>{info.getValue()}</Badge>,
  },
  {
    accessorKey: 'projectId',
    header: 'Project ID',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'createdBy',
    header: 'Created By',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'created',
    header: 'Created At',
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
  },
  {
    accessorKey: 'updatedBy',
    header: 'Updated By',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'updated',
    header: 'Updated At',
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
  },
  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row, table }) => {
      const { onDelete, onView } = table.options.meta as {
        onDelete: (id: string) => void
        onView: (id: string) => void
      }
      return (
        <div className="flex space-x-2">
          <Button onClick={() => onView(row.original.id)} size="icon">
            <EyeIcon className="w-5 h-5" />
          </Button>
          <Button
            onClick={() => onDelete(row.original.id)}
            size="icon"
            variant="destructive"
          >
            <TrashIcon className="w-5 h-5" />
          </Button>
        </div>
      )
    },
  },
]
