import { useNavigate } from 'react-router-dom'

import {
  AssetType,
  AssetWithPresignedUrl,
} from '@droidmap/asset-service-contract'

import { Card, CardContent, CardHeader, CardTitle } from '../../components/Card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/Tabs'
import { formatBytes } from '../../lib/utils'
import ImageCard from '../ImageCard'

const displayableTypes = new Set<AssetType>([AssetType.Jpeg, AssetType.Pdf])

const isDisplayable = (assetType: AssetType): boolean =>
  displayableTypes.has(assetType)

const AssetGrid = ({ assets }: { assets: AssetWithPresignedUrl[] }) => {
  const navigate = useNavigate()

  const handleTiffClick = (assetId: string) => {
    navigate(`/maps/${assetId}`)
  }

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {assets.map((asset, index) => (
        <ImageCard
          key={`asset-${index}`}
          url={
            isDisplayable(asset.assetType)
              ? asset.presignedUrl
              : 'icon_white_square.png'
          }
          title={asset.name}
          onClick={
            asset.assetType === AssetType.Tiff
              ? () => handleTiffClick(asset.id)
              : undefined
          }
          body={
            <div className="space-y-1">
              <p className="text-sm font-medium text-white">
                {formatBytes(asset.size || 0)}
              </p>
              {asset.dimensions && (
                <p className="text-sm text-white">
                  {asset.dimensions.width} x {asset.dimensions.height} px
                </p>
              )}
            </div>
          }
        />
      ))}
    </div>
  )
}

const TabbedAssetView = ({
  groupedAssets,
}: {
  groupedAssets: Record<string, AssetWithPresignedUrl[]>
}) => (
  <Tabs defaultValue="all" className="mt-6">
    <TabsList>
      <TabsTrigger value="all">All Assets</TabsTrigger>
      <TabsTrigger value="maps">Map Assets</TabsTrigger>
      <TabsTrigger value="other">Other Assets</TabsTrigger>
    </TabsList>

    <TabsContent value="all">
      <div className="space-y-8">
        {groupedAssets?.['mapAssets']?.length > 0 && (
          <Card>
            <CardHeader>
              <CardTitle>Map Assets</CardTitle>
            </CardHeader>
            <CardContent>
              <AssetGrid assets={groupedAssets['mapAssets']} />
            </CardContent>
          </Card>
        )}

        {groupedAssets?.['otherAssets']?.length > 0 && (
          <Card>
            <CardHeader>
              <CardTitle>Other Assets</CardTitle>
            </CardHeader>
            <CardContent>
              <AssetGrid assets={groupedAssets['otherAssets']} />
            </CardContent>
          </Card>
        )}
      </div>
    </TabsContent>

    <TabsContent value="maps">
      <Card>
        <CardContent className="pt-6">
          <AssetGrid assets={groupedAssets['mapAssets'] || []} />
        </CardContent>
      </Card>
    </TabsContent>

    <TabsContent value="other">
      <Card>
        <CardContent className="pt-6">
          <AssetGrid assets={groupedAssets['otherAssets'] || []} />
        </CardContent>
      </Card>
    </TabsContent>
  </Tabs>
)

const SimpleAssetView = ({ assets }: { assets: AssetWithPresignedUrl[] }) => (
  <Card className="mt-6">
    <CardContent className="pt-6">
      <AssetGrid assets={assets} />
    </CardContent>
  </Card>
)

export { TabbedAssetView, SimpleAssetView }
