import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../components/Button'
import DataTable from '../../components/DataTable'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { useFormData } from '../../hooks/useFormData'
import { fetchProjects } from '../../lib/clientHelpers'
import { columns } from './columns'

export default function Projects() {
  const auth = useAuth()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [createItemOpen, setCreateItemOpen] = useState(false)

  const [createState, handleCreateChange, resetCreateForm] = useFormData({
    projectName: '',
    userId: '',
  })

  const { projectClient } = useClient()

  const { data: projects = [], isLoading: isLoadingProjects } = useQuery({
    queryKey: ['projects.orgId', auth.activeOrganisation.id],
    queryFn: () => fetchProjects(auth.activeOrganisation.id, projectClient),
  })

  const createMutation = useMutation({
    mutationFn: async (event: React.FormEvent) => {
      event.preventDefault()
      setCreateItemOpen(false)

      return await projectClient.createProject({
        name: createState.projectName,
        orgId: auth.activeOrganisation.id,
      })
    },
    onSuccess: () => {
      resetCreateForm()
      queryClient.invalidateQueries({
        queryKey: ['projects.orgId', auth.activeOrganisation.id],
      })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async (projectId: string) => {
      await projectClient.deleteProject({ projectId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['projects.orgId', auth.activeOrganisation.id],
      })
    },
  })

  const handleCreateItem = async () => {
    setCreateItemOpen(true)
  }

  const handleViewItem = async (projectId: string) => {
    navigate(`/projects/${projectId}`)
  }

  return (
    <div>
      <Modal
        title="Create Project"
        icon={<PlusCircleIcon />}
        isOpen={createItemOpen}
        onClose={() => {
          setCreateItemOpen(false)
          resetCreateForm()
        }}
      >
        <form onSubmit={createMutation.mutate} className="space-y-6">
          <Input
            label="Project Name"
            id="projectName"
            name="projectName"
            type="text"
            autoFocus={true}
            value={createState.projectName}
            onChange={handleCreateChange}
          />
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button type="submit" disabled={!createState.projectName}>
              Submit
            </Button>
          </div>
        </form>
      </Modal>

      <div>
        <DataTable
          data={projects}
          columns={columns}
          title="Projects"
          description="Projects you are a member of."
          isLoading={isLoadingProjects}
          onCreate={handleCreateItem}
          tableOptions={{
            meta: {
              onDelete: deleteMutation.mutate,
              onView: handleViewItem,
            },
          }}
        />
      </div>
    </div>
  )
}
