import * as d3 from 'd3'
import React, { useEffect, useRef } from 'react'

import { Card, CardContent, CardHeader } from '../Card'

interface DataPoint {
  timestamp: number
  value: number
}

interface ResourceUsageGraphProps {
  title: string
  data: DataPoint[]
  color: string
  unit: string
  maxValue?: number
  className?: string // Add className prop
}

interface Margin {
  top: number
  right: number
  bottom: number
  left: number
}

const ResourceUsageGraph: React.FC<ResourceUsageGraphProps> = ({
  title,
  data,
  color,
  unit,
  maxValue,
  className,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    if (!svgRef.current || data.length === 0) return

    const svg = d3.select(svgRef.current)
    svg.selectAll('*').remove() // Clear previous render

    const margin: Margin = { top: 20, right: 30, bottom: 30, left: 40 }
    const width: number =
      svgRef.current.clientWidth - margin.left - margin.right
    const height: number =
      svgRef.current.clientHeight - margin.top - margin.bottom

    const x: d3.ScaleTime<number, number> = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => new Date(d.timestamp)) as [Date, Date])
      .range([0, width])

    const y: d3.ScaleLinear<number, number> = d3
      .scaleLinear()
      .domain([0, maxValue || d3.max(data, (d) => d.value) || 0])
      .range([height, 0])

    const line = d3
      .line<DataPoint>()
      .x((d) => x(new Date(d.timestamp)))
      .y((d) => y(d.value))

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    // Add the X Axis
    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(5))
      .attr('color', 'var(--color-element3)')
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-45)')

    // Add the Y Axis
    g.append('g')
      .call(
        d3
          .axisLeft(y)
          .ticks(5)
          .tickFormat((d) => `${d}${unit}`),
      )
      .attr('color', 'var(--color-element3)')

    // Add grid lines
    g.append('g')
      .attr('class', 'grid')
      .call(
        d3
          .axisLeft(d3.scaleLinear().range([height, 0]))
          .tickSize(-width)
          .tickFormat(() => ''),
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .attr('stroke', '#666')
          .attr('stroke-dasharray', '2,2'),
      )

    // Add the line path
    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', 2)
      .attr('d', line)

    // Add a tooltip
    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', '0')
      .style('position', 'absolute')
      .style('background-color', 'var(--color-surface2)')
      .style('border', '1px solid var(--color-surface4)')
      .style('border-radius', '5px')
      .style('padding', '10px')
      .style('color', 'var(--color-element1)')

    // Add tooltip interactions
    const bisect = d3.bisector<DataPoint, number>((d) => d.timestamp).left

    g.append('rect')
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mousemove', (event: MouseEvent) => {
        const [xPos] = d3.pointer(event)
        const x0 = x.invert(xPos).getTime()
        const i = bisect(data, x0, 1)
        const d0 = data[i - 1]
        const d1 = data[i]
        const d = x0 - d0.timestamp > d1.timestamp - x0 ? d1 : d0

        tooltip.transition().duration(100).style('opacity', '0.9')
        tooltip
          .html(
            `Time: ${new Date(d.timestamp).toLocaleTimeString()}<br/>Value: ${d.value}${unit}`,
          )
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 28}px`)
      })
      .on('mouseout', () => {
        tooltip.transition().duration(500).style('opacity', '0')
      })
  }, [data, color, unit, maxValue])

  return (
    <Card className={`w-full h-64 ${className}`}>
      <CardHeader className="pb-2">
        <h3 className="font-semibold text-on-surface">{title}</h3>
      </CardHeader>
      <CardContent className="bg-surface-1 text-on-surface">
        <svg ref={svgRef} width="100%" height="100%" />
      </CardContent>
    </Card>
  )
}

export default ResourceUsageGraph
