import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import {
  AdjustmentsVerticalIcon,
  ArrowLeftEndOnRectangleIcon,
  Bars3Icon,
  BuildingOffice2Icon,
  ChartPieIcon,
  CircleStackIcon,
  FolderIcon,
  GlobeAsiaAustraliaIcon,
  HomeIcon,
  MoonIcon,
  PhotoIcon,
  RocketLaunchIcon,
  SunIcon,
  UserCircleIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import React, { Fragment, useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'

import LogoLight from '../assets/logo_color.svg?react'
import LogoDark from '../assets/logo_mixed.svg?react'
import { Button } from '../components/Button'
import Spinner from '../components/Spinner'
import ThemeSwitcher from '../components/ThemeSwitcher'
import { useAuth } from '../context/AuthContext'
import { ThemeContext } from '../context/ThemeContext'

// TODO
// Fix mobile sidebar logo
// Desktop Sidebar Collapseable

const navigation = [
  { name: 'Dashboard', to: '/', icon: HomeIcon, current: true },
  {
    name: 'Organisations',
    to: '/organisations',
    icon: BuildingOffice2Icon,
    current: false,
  },
  { name: 'Groups', to: '/groups', icon: UserGroupIcon, current: false },
  { name: 'Projects', to: '/projects', icon: FolderIcon, current: false },
  { name: 'Asset Sets', to: '/asset-sets', icon: PhotoIcon, current: false },
  { name: 'Datasets', to: '/datasets', icon: CircleStackIcon, current: false },
  { name: 'Analytics', to: '/analytics', icon: ChartPieIcon, current: false },
  { name: 'Maps', to: '/maps', icon: GlobeAsiaAustraliaIcon, current: false },
  { name: 'Tasks', to: '/tasks', icon: RocketLaunchIcon, current: false },
]
const settings = [
  {
    name: 'Settings',
    to: '/settings',
    icon: AdjustmentsVerticalIcon,
    current: false,
  },
]

type SidebarProps = {
  children?: React.ReactNode
}

export const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const auth = useAuth()

  const [sidebarOpen, setSidebarOpen] = useState(false)

  if (auth.isLoading || auth.userDetails === null) {
    return (
      <div>
        <Spinner size="page" className="text-primary" />
      </div>
    )
  }

  const sidebarContent = (
    <nav className="flex flex-1 flex-col">
      <ul className="-mx-2 flex flex-1 flex-col content-around space-y-1 mb-2">
        {navigation.map((item) => (
          <li key={item.name}>
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                `${
                  isActive
                    ? 'bg-primary text-on-primary shadow-md'
                    : 'text-on-surface hover:text-on-primary hover:bg-primary-light hover:shadow-md'
                } group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6`
              }
            >
              <item.icon
                className="stroke-1.5 h-6 w-6 shrink-0"
                aria-hidden="true"
              />
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <ul>
        <hr className="border-accent" />
        <li>
          <ul className="-mx-2 my-2 space-y-1">
            {settings.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? 'bg-primary text-on-primary shadow-xl'
                        : 'text-on-surface hover:text-on-primary hover:bg-primary-light hover:shadow-xl'
                    } group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6`
                  }
                >
                  <item.icon
                    className="stroke-1.5 h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  {item.name}
                </NavLink>
              </li>
            ))}
            <li>
              <div
                onClick={auth.signOut}
                className="text-on-surface hover:text-on-primary hover:bg-primary-light group flex cursor-pointer items-center gap-x-3 rounded-md p-2 text-sm leading-6 hover:shadow-lg"
              >
                <ArrowLeftEndOnRectangleIcon
                  className="stroke-1.5 h-6 w-6 shrink-0"
                  aria-hidden="true"
                />
                Logout
              </div>
            </li>
            <li>
              <div
                onClick={toggleTheme}
                className="text-on-surface hover:text-on-primary hover:bg-primary-light group flex cursor-pointer select-none items-center gap-x-3 rounded-md p-2 text-sm leading-6 hover:shadow-lg"
              >
                {theme === 'dark' ? (
                  <SunIcon
                    className="stroke-1.5 h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                ) : (
                  <MoonIcon
                    className="stroke-1.5 h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                )}
                {theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
                <div className="flex flex-grow justify-end">
                  <ThemeSwitcher />
                </div>
              </div>
            </li>
          </ul>
        </li>
        <hr className="border-accent" />
        <li className="-mx-6 mt-auto">
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              `${
                isActive
                  ? 'bg-primary text-on-primary shadow-xl'
                  : 'text-on-surface hover:text-on-primary hover:bg-primary-light hover:shadow-xl'
              } m-2 flex items-center gap-x-4 rounded-md px-4 py-3 text-sm leading-6`
            }
          >
            {({ isActive }) => (
              <>
                <div
                  className={`${
                    isActive ? 'bg-primary-light' : 'bg-primary'
                  } text-on-primary rounded-md p-2 shadow-xl`}
                >
                  <UserCircleIcon
                    className="h-8 w-8 shrink-0"
                    aria-hidden="true"
                  />
                </div>

                <span className="sr-only">Your profile</span>
                <span aria-hidden="true" className="items-center">
                  {auth.userDetails && (
                    <>
                      <p>
                        {auth.userDetails.firstName} {auth.userDetails.lastName}
                      </p>
                      <p>{auth.userDetails.userEmail}</p>
                    </>
                  )}
                </span>
              </>
            )}
          </NavLink>
        </li>
      </ul>
    </nav>
  )

  return (
    <div className="h-full">
      {/* Mobile sidebar */}
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-content bg-opacity-50 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <Button
                      className="-m-2.5 p-2.5"
                      size={'icon'}
                      variant={'outline'}
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                </TransitionChild>
                <div className="bg-surface flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-2 ring-1 ring-white/10">
                  <div className="mt-2 flex h-16 shrink-0 items-center">
                    {theme === 'dark' ? (
                      <LogoDark className="mx-auto h-12 w-full" />
                    ) : (
                      <LogoLight className="mx-auto h-12 w-full" />
                    )}
                  </div>
                  {sidebarContent}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="bg-surface text-on-surface hidden shadow-lg lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-2 overflow-y-auto px-6">
          <div className="mt-2 flex h-16 shrink-0 items-center">
            {theme === 'dark' ? (
              <LogoDark className="mx-auto h-12 w-full" />
            ) : (
              <LogoLight className="mx-auto h-12 w-full" />
            )}
          </div>
          {sidebarContent}
        </div>
      </div>

      <div className="bg-surface text-on-surface sticky top-0 z-40 flex items-center gap-x-6 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <Button
          className="-m-2.5 p-2.5 lg:hidden"
          variant={'outline'}
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </Button>
        <div className="flex-1 text-sm font-semibold leading-6">Dashboard</div>
        <a href="/">
          <span className="sr-only">Your profile</span>
          <UserCircleIcon />
        </a>
      </div>

      <main className="h-full overflow-y-auto lg:pl-72">
        <div className="h-full p-6">{children}</div>
      </main>
    </div>
  )
}
