import { Handle, NodeProps, Position } from 'reactflow'

import { formatTimeDifference } from '../../lib/utils'
import { Status, StatusIcon } from '../Status'

export enum DroidMapStatusNodeType {
  Input = 'input',
  Output = 'output',
  Default = 'default',
}

export interface DroidMapStatusNodeData {
  label: string
  status: Status
  type: DroidMapStatusNodeType
  startTime: number
  endTime: number
}

/**
 * A React functional component that represents a status node in the DroidMap flow.
 *
 * @param {NodeProps<DroidMapStatusNodeData>} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered status node component.
 *
 * The component conditionally renders a target handle on the left if the node type is either
 * `Output` or `Default`. It also conditionally renders a source handle on the right if the node
 * type is either `Input` or `Default`.
 *
 * The main content of the node includes:
 * - A status icon that reflects the current status.
 * - A ping animation if the status is `Running`.
 * - A label displaying the node's label.
 * - A time difference formatted string showing the duration between `startTime` and `endTime`.
 *
 * @component
 * @example
 * ```tsx
 * <DroidMapStatusNode data={nodeData} />
 * ```
 */
export const DroidMapStatusNode: React.FC<
  NodeProps<DroidMapStatusNodeData>
> = ({ data }) => (
  <>
    {(data.type === DroidMapStatusNodeType.Output ||
      data.type === DroidMapStatusNodeType.Default) && (
      <Handle type="target" position={Position.Left} />
    )}
    <div className="flex items-center align-center space-x-2 border rounded-md p-2 bg-surface-1 border-accent shadow-md w-56">
      <div className="left-2 w-5 h-5">
        <StatusIcon status={data.status} className="absolute" />
        {data.status === Status.Running && (
          <StatusIcon status={data.status} className="absolute animate-ping" />
        )}
      </div>
      <div className="flex justify-between w-full">
        <div className="text-sm font-medium text-on-surface">{data.label}</div>
        <div className="text-sm font-medium text-on-surface">
          {formatTimeDifference(data.startTime, data.endTime)}
        </div>
      </div>
    </div>
    {(data.type === DroidMapStatusNodeType.Input ||
      data.type === DroidMapStatusNodeType.Default) && (
      <Handle type="source" position={Position.Right} />
    )}
  </>
)
