import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'

import { Button } from '../../components/Button'
import DataTable from '../../components/DataTable'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import Select from '../../components/Select'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { useFormData } from '../../hooks/useFormData'
import { fetchGroups, fetchOrganisations } from '../../lib/clientHelpers'
import { columns } from './columns'

export default function Groups() {
  const auth = useAuth()
  const queryClient = useQueryClient()
  const [createItemOpen, setCreateItemOpen] = useState(false)
  const [viewItemOpen, setViewItemOpen] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState<string>('')

  const [createState, handleCreateChange, resetCreateForm] = useFormData({
    groupName: '',
    orgId: '',
  })

  const { organisationClient } = useClient()

  const { data: groups = [], isLoading: isLoadingGroups } = useQuery({
    queryKey: [
      'groups',
      auth.userDetails.id,
      auth.activeOrganisation.id,
      organisationClient,
    ],
    queryFn: () =>
      fetchGroups(
        organisationClient,
        auth.userDetails.id,
        auth.activeOrganisation.id,
      ),
  })

  const { data: organisations = [] } = useQuery({
    queryKey: ['organisations', auth.userDetails.id],
    queryFn: () => fetchOrganisations(organisationClient, auth.userDetails.id),
  })

  const { data: group } = useQuery({
    queryKey: ['group', selectedGroupId],
    queryFn: () => organisationClient.getGroup(selectedGroupId),
    enabled: !!selectedGroupId,
  })

  const createMutation = useMutation({
    mutationFn: async (event: React.FormEvent) => {
      event.preventDefault()
      setCreateItemOpen(false)

      const newGroup = await organisationClient.createGroup({
        name: createState.groupName,
        orgId: createState.orgId,
      })

      await organisationClient.createGroupUser({
        groupId: newGroup.id,
        userId: auth.userDetails.id,
        orgId: auth.activeOrganisation.id,
      })

      return newGroup
    },
    onSuccess: () => {
      resetCreateForm()
      queryClient.invalidateQueries({
        queryKey: ['groups', auth.userDetails.id],
      })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async (groupId: string) => {
      await organisationClient.deleteGroup({ groupId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['groups', auth.userDetails.id],
      })
    },
  })

  const handleCreateItem = async () => {
    setCreateItemOpen(true)
  }

  const handleView = async (event: React.FormEvent) => {
    event.preventDefault()
    setViewItemOpen(false)
  }

  const handleViewItem = async (groupId: string) => {
    setSelectedGroupId(groupId)
    setViewItemOpen(true)
  }

  const Inputs = [
    { id: 'groupName', label: 'Group Name', value: group?.name },
    { id: 'groupId', label: 'Group Id', value: group?.id },
    {
      id: 'groupCreated',
      label: 'Group Created',
      value: group?.created?.toString(),
    },
    {
      id: 'groupUpdated',
      label: 'Group Updated',
      value: group?.updated?.toString(),
    },
  ]

  const renderInputs = () => {
    return Inputs.map((field) => (
      <div key={field.id}>
        <Input
          label={field.label}
          id={field.id}
          name={field.id}
          type="text"
          readOnly={true}
          value={field.value}
        />
      </div>
    ))
  }

  return (
    <div>
      <Modal
        title="Create Group"
        icon={<PlusCircleIcon />}
        isOpen={createItemOpen}
        onClose={() => {
          setCreateItemOpen(false)
          resetCreateForm()
        }}
      >
        <form onSubmit={createMutation.mutate} className="space-y-6">
          <Input
            label="Group Name"
            id="groupName"
            name="groupName"
            type="text"
            autoFocus={true}
            value={createState.groupName}
            onChange={handleCreateChange}
          />
          <Select
            label="Select Org"
            id="orgId"
            name="orgId"
            value={createState.orgId}
            onChange={handleCreateChange}
          >
            <option value="">Select an organisation</option>
            {organisations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </Select>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              type="submit"
              disabled={!createState.orgId || !createState.groupName}
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>

      <Modal
        title="View Group"
        icon={<PencilSquareIcon />}
        isOpen={viewItemOpen}
        onClose={() => setViewItemOpen(false)}
      >
        <form onSubmit={handleView} className="flex-col space-y-6">
          {renderInputs()}

          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button type="submit">Close</Button>
          </div>
        </form>
      </Modal>

      <div>
        <DataTable
          data={groups}
          columns={columns}
          title="Groups"
          description="Groups you are a member of."
          isLoading={isLoadingGroups}
          onCreate={handleCreateItem}
          tableOptions={{
            meta: {
              onDelete: deleteMutation.mutate,
              onView: handleViewItem,
            },
          }}
        />
      </div>
    </div>
  )
}
