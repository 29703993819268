import { useQuery } from '@tanstack/react-query'

import { Button } from '../../components/Button'
import Input from '../../components/Input'
import Logo from '../../components/Logo'
import Spinner from '../../components/Spinner'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'

export default function Dashboard() {
  const { userDetails, signOut } = useAuth()
  const { userClient } = useClient()

  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['user', userDetails.id],
    queryFn: () => userClient.getUser({ id: userDetails.id }),
  })

  if (isError) {
    return <div>Error {error.message}</div>
  }

  if (isLoading) {
    return <Spinner size="page" className="text-primary" />
  }

  return (
    <div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Logo className="mx-auto h-12 w-full" />
          <h2 className="text-on-background mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
            {isLoading || !user
              ? 'Welcome'
              : `Welcome ${user?.firstName} ${user?.lastName}`}
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className={`${isLoading && 'animate-pulse'} space-y-6`}>
            <Input
              label="Email Address"
              id="email"
              name="email"
              type="text"
              readOnly
              value={user?.userEmail || ''}
            />
            <Input
              label="DroidMap ID"
              id="id"
              name="id"
              type="text"
              readOnly
              value={user?.id || ''}
            />
            <Input
              label="Created"
              id="created"
              name="created"
              type="text"
              readOnly
              value={user?.created?.toString() || ''}
            />
            <Input
              label="Updated"
              id="updated"
              name="updated"
              type="text"
              readOnly
              value={user?.updated?.toString() || ''}
            />
            <div>
              <Button size="wide" onClick={signOut}>
                Sign Out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
