import { useQuery } from '@tanstack/react-query'

import { AssetSetType } from '@droidmap/asset-service-contract'

import Input from '../../components/Input'
import MultipleFileUpload from '../../components/MultiFileUpload'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { useAssetUpload } from '../../hooks/useAssetUpload'
import { useFormData } from '../../hooks/useFormData'
import { fetchProjects } from '../../lib/clientHelpers'

export default function AssetSetCreate() {
  const auth = useAuth()

  const [createState, handleCreateChange, resetCreateForm] = useFormData({
    assetName: '',
    projectId: '',
    assetType: '',
  })

  const { projectClient } = useClient()

  const { filesWithProgress, handleFileChange, handleUpload } = useAssetUpload({
    name: createState.assetName,
    projectId: createState.projectId,
    assetSetType: createState.assetType as AssetSetType,
  })

  const { data: projects = [], isLoading } = useQuery({
    queryKey: ['projects', auth.activeOrganisation.id, projectClient],
    queryFn: () => fetchProjects(auth.activeOrganisation.id, projectClient),
  })

  if (isLoading) {
    return <Spinner size="page" className="text-primary" />
  }

  return (
    <div className="space-y-2">
      <form onSubmit={resetCreateForm} className="space-y-6">
        <Input
          type="text"
          name="assetName"
          value={createState.assetName}
          onChange={handleCreateChange}
          placeholder="Asset Set Name"
        />
        <Select
          name="projectId"
          value={createState.projectId}
          onChange={handleCreateChange}
        >
          <option value="">Select Project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </Select>
        <Select
          name="assetType"
          value={createState.assetType}
          onChange={handleCreateChange}
        >
          <option value="">Select Asset Type</option>
          <option value={AssetSetType.DroneImages}>Drone Images</option>
          <option value={AssetSetType.OdmOutputs}>ODM Outputs</option>
        </Select>
      </form>
      <MultipleFileUpload
        useFileUpload={() => ({
          filesWithProgress,
          handleFileChange,
          handleUpload,
        })}
      />
    </div>
  )
}
