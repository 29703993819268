import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeContext'

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    useContext(ThemeContext)
    return (
      <div className="mt-2 flex items-center align-center outline-none">
        <input
          type="checkbox"
          {...props}
          ref={ref}
          className={`bg-white text-white border border-accent w-4 h-4 rounded-md flex items-center align-center justify-center focus:ring-inset focus:ring-offset-primary focus:ring-0 shadow-sm`}
        />
        <label
          htmlFor={props.name ?? props.id}
          className="ml-2 block text-sm font-medium text-on-surface"
        >
          {props.label}
        </label>
      </div>
    )
  },
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
