interface ErrorMessageProps {
  content: string
}

export default function ErrorMessage(props: ErrorMessageProps) {
  return (
    <div role="alert">
      <div className="rounded  bg-destructive px-2 py-2 text-xs text-on-primary">
        {props.content}
      </div>
    </div>
  )
}
