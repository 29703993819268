import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthForm from '../../components/AuthForm'
import AuthPage from '../../components/AuthPage'
import { Button } from '../../components/Button'
import ErrorMessage from '../../components/ErrorMessage'
import Input from '../../components/Input'
import { useAuth } from '../../context/AuthContext'

export default function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [generateCodeSuccess, setGenerateCodeSuccess] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      auth
        .forgotPassword(email)
        .then(() => {
          setGenerateCodeSuccess(true)
        })
        .catch((err) => setError((err as Error).message))
    },
    [email, auth],
  )

  const handleCodeConfirm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      auth.confirmPassword(email, code, newPassword).then(() => navigate('/'))
    },
    [email, code, newPassword, auth, navigate],
  )

  return (
    <AuthPage title="Forgot your password?">
      {generateCodeSuccess ? (
        <AuthForm onSubmit={handleCodeConfirm}>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="flex justify-center text-sm font-medium leading-6 text-on-background">
              <p>Please check your email for the confirmation code.</p>
            </div>
          </div>
          <Input
            label="Confirmation code"
            type="text"
            placeholder="Confirmation code"
            value={code}
            required
            onChange={(e) => setCode(e.target.value)}
          />
          <Input
            label="New password"
            type="password"
            placeholder="Password"
            value={newPassword}
            required
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {error && <ErrorMessage content={error} />}
          <Button type="submit">Reset my password</Button>
        </AuthForm>
      ) : (
        <AuthForm onSubmit={handleSubmit}>
          <Input
            label="Email address"
            type="email"
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />

          {error && <ErrorMessage content={error} />}

          <Button type="submit" className="w-full">
            Reset my password
          </Button>
        </AuthForm>
      )}
    </AuthPage>
  )
}
