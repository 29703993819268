import React from 'react'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  className?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, label, ...rest } = props
  const id =
    props.id ||
    props.name ||
    `input-${label?.toLowerCase().replace(/\s+/g, '-')}`

  return (
    <div className="flex flex-col justify-center">
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-on-surface"
        >
          {label}
        </label>
      )}
      <div className={label ? 'mt-2' : ''}>
        <input
          {...rest}
          id={id}
          ref={ref}
          className={`bg-surface text-on-surface autofill:text-on-surface/75 block w-full rounded-md border border-accent py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${className}`}
        />
      </div>
    </div>
  )
})

Input.displayName = 'Input'
export default Input
