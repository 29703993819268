import React from 'react'

import { cn } from '../../lib/utils'
import { Button } from '../Button'
import { Skeleton } from '../Skeleton'

interface DetailCardProps {
  title: string
  image: string
  details: string[]
  onClick?: () => void
  className?: string
  isLoading?: boolean
  imageAlt?: string
  badge?: string
}

const DetailCard: React.FC<DetailCardProps> = ({
  title,
  image,
  details,
  onClick,
  className,
  isLoading = false,
  imageAlt = '',
  badge,
}) => {
  const [imageError, setImageError] = React.useState(false)

  if (isLoading) {
    return (
      <div
        className={cn(
          'aspect-square rounded-lg shadow-md overflow-hidden bg-gray-100',
          className,
        )}
      >
        <Skeleton className="w-full h-full" />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'group relative overflow-hidden rounded-lg shadow-md transition-all duration-200 hover:shadow-lg',
        'focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2',
        className,
      )}
    >
      {/* Image Container */}
      <div className="aspect-square w-full">
        {!imageError ? (
          <img
            src={image}
            alt={imageAlt}
            className="h-full w-full object-cover transition-transform duration-200 group-hover:scale-105"
            onError={() => setImageError(true)}
          />
        ) : (
          <div className="flex h-full w-full items-center justify-center bg-surface-3">
            <span className="text-on-surface">Image unavailable</span>
          </div>
        )}
      </div>

      {/* Badge */}
      {badge && (
        <div className="absolute right-2 top-2 rounded-full bg-primary px-2 py-1 text-xs font-semibold text-white">
          {badge}
        </div>
      )}

      {/* Content Overlay */}
      <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/90 to-black/0 p-4 transition-all duration-200">
        <div className="space-y-1">
          <h3 className="text-lg font-bold text-white">{title}</h3>
          <div className="space-y-0.5">
            {details.map((detail, index) => (
              <p key={index} className="text-sm text-gray-200">
                {detail}
              </p>
            ))}
          </div>
        </div>
      </div>

      {/* Interactive Button */}
      <Button
        onClick={onClick}
        className="absolute inset-0 h-full w-full rounded-lg bg-transparent hover:bg-white/15 focus:outline-none"
        aria-label={`View details for ${title}`}
      >
        <span className="sr-only">View details for {title}</span>
      </Button>
    </div>
  )
}

export default DetailCard
