import {
  ArrowPathIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import * as Collapsible from '@radix-ui/react-collapsible'
import React, { useEffect, useState } from 'react'

import { Button } from '../Button'
import { Card, CardContent, CardHeader } from '../Card'
import Input from '../Input'
import { Status, StatusIcon } from '../Status'

interface Log {
  id: string
  message: string
  timestamp: string
}

interface Task {
  id: string
  name: string
  status: Status
  logs: Log[]
  runtime: number // in seconds
}

interface LogViewerProps {
  tasks: Task[]
  onRefresh: () => void
}

const HighlightedText: React.FC<{ text: string; highlight: string }> = ({
  text,
  highlight,
}) => {
  if (!highlight.trim()) {
    return <span className="text-element-1">{text}</span>
  }
  const regex = new RegExp(`(${highlight})`, 'gi')
  const parts = text.split(regex)

  return (
    <span>
      {parts.map((part, i) =>
        regex.test(part) ? (
          <mark key={i} className="bg-yellow-200">
            {part}
          </mark>
        ) : (
          <span className="text-element-1" key={i}>
            {part}
          </span>
        ),
      )}
    </span>
  )
}

const formatRuntime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  return [hours, minutes, remainingSeconds]
    .map((v) => v.toString().padStart(2, '0'))
    .join(':')
}

const LogViewer: React.FC<LogViewerProps> = ({ tasks, onRefresh }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [openTasks, setOpenTasks] = useState<string[]>([])
  const [userOpenedTasks, setUserOpenedTasks] = useState<string[]>([])

  const toggleTask = (taskId: string) => {
    setOpenTasks((prev) =>
      prev.includes(taskId)
        ? prev.filter((id) => id !== taskId)
        : [...prev, taskId],
    )
    setUserOpenedTasks((prev) =>
      prev.includes(taskId)
        ? prev.filter((id) => id !== taskId)
        : [...prev, taskId],
    )
  }

  const collapseAll = () => {
    setOpenTasks([])
    setUserOpenedTasks([])
  }

  useEffect(() => {
    if (searchTerm) {
      const tasksWithMatchingLogs = tasks
        .filter((task) =>
          task.logs.some((log) =>
            log.message.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
        )
        .map((task) => task.id)

      setOpenTasks([...new Set([...tasksWithMatchingLogs, ...userOpenedTasks])])
    } else {
      setOpenTasks(userOpenedTasks)
    }
  }, [searchTerm, tasks, userOpenedTasks])

  return (
    <Card className="w-full">
      <CardHeader>
        <div className="flex justify-between items-center">
          <h1 className="text-on-surface font-semibold">Log Viewer</h1>
          <div className="flex items-center space-x-2">
            <div className="relative flex items-center">
              <MagnifyingGlassIcon className="absolute left-2 w-5 h-5 text-on-surface" />
              <Input
                type="text"
                placeholder="Search logs..."
                className="pl-8"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Button
              size={'icon'}
              onClick={onRefresh}
              variant={'default'}
              title="Refresh"
            >
              <ArrowPathIcon className="w-5 h-5" />
            </Button>
            <Button
              size={'icon'}
              variant={'secondary'}
              onClick={collapseAll}
              title="Collapse All"
            >
              <ChevronRightIcon className="w-5 h-5" />
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {tasks.map((task) => (
            <Collapsible.Root
              key={task.id}
              open={openTasks.includes(task.id)}
              onOpenChange={() => toggleTask(task.id)}
            >
              <Collapsible.Trigger
                className={`flex justify-between items-center w-full p-2 rounded-md transition-colors duration-200 ${
                  openTasks.includes(task.id)
                    ? 'bg-surface-2'
                    : 'bg-surface-1 hover:bg-surface-2'
                }`}
              >
                <div className="flex items-center space-x-2">
                  <StatusIcon status={task.status} />
                  <span className="font-medium text-on-surface">
                    {task.name}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-xs bg-surface-1 text-on-surface px-2 py-1 rounded-full">
                    Runtime: {formatRuntime(task.runtime)}
                  </span>
                  <ChevronRightIcon
                    className={`w-5 h-5 text-on-surface transition-transform duration-200 ease-in-out ${openTasks.includes(task.id) ? 'transform rotate-90' : ''}`}
                  />
                </div>
              </Collapsible.Trigger>
              <Collapsible.Content>
                <div className="mt-2 space-y-1 bg-surface-1 rounded-md py-2 mx-2">
                  {task.logs.map((log, index) => (
                    <div
                      key={log.id}
                      className="flex text-sm transition-colors duration-200 hover:bg-surface-2"
                    >
                      <div className="flex-shrink-0 min-w-[2.5rem] pr-2 text-right">
                        <span className="text-xs text-on-surface/50 inline-block">
                          {index + 1}
                        </span>
                      </div>
                      <div className="flex-grow overflow-hidden">
                        <span className="text-on-surface whitespace-nowrap">
                          {log.timestamp}
                        </span>{' '}
                        -{' '}
                        <HighlightedText
                          text={log.message}
                          highlight={searchTerm}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </Collapsible.Content>
            </Collapsible.Root>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

export default LogViewer
